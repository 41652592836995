import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const CustomButton = ({ className, children, type, onClick, isDisabled }) => {
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      type={type ? type : "button"}
      className={classNames(
        twMerge(
          "text-xs bg-main p-2 rounded-lg text-white px-5 hover:bg-main/70",
          className
        ),
        isDisabled && "cursor-not-allowed bg-main/30 hover:bg-main/30"
      )}
    >
      {children}
    </button>
  );
};

export default CustomButton;
